$(document).ready(function()
{
    /**
     * BODY CLASS HANDLING
     */
    $('body').placeholderFix().browserNotice().backToTop();

    // Toggle the "hovering" class on elements
    $('.tapHover').on('touchend', tapHover);

    /**
     * ACCORDION CALL
     */
    $('.accHandler').accordion({openFirst:true});

    /**
     * ESCAPE THE MAILMASK LINKS
     */
    $('a.escape').noSpam();

    /**
     * FORM VALIDATION AND SUBMIT VIA AJAX
     */
    $('form[data-validate="true"]').each(function()
    {
        $(this).validate();
    });

    /**
     * CALL COLORBOX FOR IMAGES
     */
    $('a[data-rel^=lightbox]:not(.nolightbox)').lightcase({
        showCaption: false,
        labels: lightcaseLabels,
        type: 'image'
    });

    /**
     * CALL COLORBOX FOR EXTERNAL LINKS / YOUTUBE VIDEOS / ETC. 
     */
    // $('a[data-rel^=lightbox-external]:not(.nolightbox)').lightcase({
    //     showCaption: false,
    //     labels: lightcaseLabels,
    //     type: 'iframe',
    //     maxWidth: $(window).width() * 0.8,
    //     maxHeight: $(window).height() * 0.8,
    //     iframe: {
    //         width: '600',
    //         height: $(window).height() * 0.8
    //     }
    // });

    /**
     * GET BREAKPOINTS FROM _CONFIG.SCSS FOR IF-STATEMENTS
     */
    if ( breakpoint['medium'] ) {

    }

    /**
     * detect external links and add target=_blank
     */
    $('a:external').attr('target', '_blank');


     ///////slider

      $('.slider-content').slick({
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 2500,
      arrows:false,
      dots:false,
      fade:true,
    });

       /*map options*/
    $(".overlay-map").on('click', function(){
        $(".overlay-map").addClass("no-overlay");
        $(".info-footer").addClass("no-overlay");
    });
    $(".map-index").mouseleave(function(){
        $('.overlay-map').removeClass("no-overlay");
        $('.info-footer').removeClass("no-overlay");
        
    });

    ///////navigation scroll
var num = 2; //number of pixels before modifying styles
$(window).bind('scroll', function () {
    if ($(window).scrollTop() > num) {

        $('.nav-holder').addClass('navi-back');



    } else {

        $('.nav-holder').removeClass('navi-back');



    }
});
});
/////////////// END DOC READY

/**
* PLACE YOUR OWN SCRIPTS HERE
*/